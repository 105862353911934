import { PRODUCT_DATA } from "./AppData";

export const ROUTE_PATHS = {
    HOME_PAGE: "/",
    PRODUCT_DETAILS: "/product",
    DEFAULT_PRODUCT: "/product/" + PRODUCT_DATA.litterBox.id,
    ABOUT: "/about",
    TNC: "/terms",
    PRIVACY: "/privacy",
    REFUND_POLICY: "/refund-policy"
}
Object.freeze(ROUTE_PATHS);