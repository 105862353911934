import React from "react";

import classes from "./TestimonialSection.module.css";
import CarouselCustom from "../../../../components/molecules/CarouselCustom/CarouselCustom";
import TestimonialItem from "../TestimonialItem/TestimonialItem";

const TestimonialSection = ({data}) => {
  return (
    <section className={classes.MainContainer}>
      <h2 className={classes.MainHeading}>Fan-tastic Feedback</h2>

      <div className={classes.TestimonialsWrapper}>
        <div className={classes.BannerCarousel}>
          <CarouselCustom
            deviceType={window.screen.availWidth <= 400 ? "mobile" : "desktop"}
          >
            {data.testimonials.map((item, pos) => (
              <TestimonialItem
                key={pos}
                message={item.message}
                username={item.name}
                rating={item.rating}
              />
            ))}
          </CarouselCustom>
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;
