import React from "react";
import Carousel from "react-multi-carousel";

import classes from "./CarouselCustom.module.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-multi-carousel/lib/styles.css";

const CarouselCustom = (props) => {
  const CustomDot = ({ onClick, ...rest }) => {
    const {
      onMove,
      index,
      active,
      carouselState: { currentSlide, deviceType },
    } = rest;
    return (
      <div
        className={[
          classes.CarouselIndicator,
          active ? classes.Active : null,
        ].join(" ")}
        onClick={() => onClick()}
      ></div>
    );
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className={classes.BannerCarousel}>
      <Carousel
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        containerClass="carousel-container"
        deviceType={props.deviceType}
        arrows={false}
        showDots={true}
        customDot={<CustomDot />}
      >
        {props.children}
      </Carousel>
    </div>
  );
};

export default CarouselCustom;
