import React from "react";
import { Link } from "react-router-dom";
import { useScrollPosition } from "../../../hooks/useScrollPosition";

import classes from "./Topbar.module.css";
import Logo from "../../../assets/logos/kipenzi-logo-black.svg";
import { ROUTE_PATHS } from "../../../utils/RoutePath";
import { PRODUCT_DATA } from "../../../utils/AppData";

const Topbar = (props) => {
  const scrollPosition = useScrollPosition();

  const renderMenuItems = () => (
    <div className={classes.MenuWrapper}>
      <Link
        to={ROUTE_PATHS.PRODUCT_DETAILS + "/" + PRODUCT_DATA.litterBox.id}
        className={classes.MenuItem}
      >
        Smart Litter Pal
      </Link>
      <Link
        to={ROUTE_PATHS.PRODUCT_DETAILS + "/" + PRODUCT_DATA.litterSand.id}
        className={classes.MenuItem}
      >
        Bentonite Litter
      </Link>
      <div className={classes.Dropdown}>
        <p className={[classes.MenuItem].join(" ")}>Shampoo</p>
        <div className={classes.DropdownWrapper}>
          <Link
            to={
              ROUTE_PATHS.PRODUCT_DETAILS +
              "/" +
              PRODUCT_DATA.shampooTickFlea.id
            }
            className={classes.MenuItem}
          >
            Tick & Flea Shampoo
          </Link>
          <Link
            to={ROUTE_PATHS.PRODUCT_DETAILS + "/" + PRODUCT_DATA.shampoo8In1.id}
            className={classes.MenuItem}
          >
            8-in-1 Shampoo
          </Link>
          <Link
            to={
              ROUTE_PATHS.PRODUCT_DETAILS +
              "/" +
              PRODUCT_DATA.shampooPuppyKitten.id
            }
            className={classes.MenuItem}
          >
            Puppy & Kitten Shampoo
          </Link>
        </div>
      </div>
    </div>
  );

  return (
    <div
      className={[
        classes.MainContainer,
        scrollPosition > 0 ? classes.TopbarShadow : null,
      ].join(" ")}
    >
      <div>
        <Link className={classes.LogoWrapper} to={ROUTE_PATHS.HOME_PAGE}>
          <img className={classes.Logo} src={Logo} alt="Kipenzi Logo" />
        </Link>
      </div>
      {renderMenuItems()}
      {/* <a
        href={"https://wa.me/919899113898"}
        title="WhatsApp Chat"
        rel="noopener noreferrer"
        target="_blank"
      >
        Contact Us
      </a> */}
    </div>
  );
};

export default Topbar;
