import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import TestimonialSection from "./components/TestimonialSection/TestimonialSection";
import ApplyNowSection from "./components/OrderNowSection/OrderNowSection";
import FeatureSection from "./components/FeatureSection/FeatureSection";
import ProductPreviewSection from "./components/ProductPreviewSection/ProductPreviewSection";

import classes from "./ProductDetailPage.module.css";
import WhatsAppIcon from "../../assets/icons/whatsapp-icon.png";
import { PRODUCT_DATA } from "../../utils/AppData";
import { Helmet } from "react-helmet";

const ProductDetailPage = (props) => {
  const [data, setData] = React.useState(PRODUCT_DATA.litterBox);
  const slug = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    switch (slug.id) {
      case PRODUCT_DATA.litterSand.id:
        setData(PRODUCT_DATA.litterSand);
        break;
      case PRODUCT_DATA.shampooTickFlea.id:
        setData(PRODUCT_DATA.shampooTickFlea);
        break;
      case PRODUCT_DATA.shampoo8In1.id:
        setData(PRODUCT_DATA.shampoo8In1);
        break;
      case PRODUCT_DATA.shampooPuppyKitten.id:
        setData(PRODUCT_DATA.shampooPuppyKitten);
        break;
      default:
        setData(PRODUCT_DATA.litterBox);
    }
  }, [slug]);

  const reviewsRef = React.useRef(null);
  const orderRef = React.useRef(null);

  const scrollToReviewSection = () => {
    reviewsRef.current.scrollIntoView();
  };

  const scrollToOrderSection = () => {
    orderRef.current.scrollIntoView();
  };

  return (
    <main className={classes.MainContainer}>
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>{data.productName}</title>
      </Helmet> */}
      <ProductPreviewSection
        scrollToOrderSection={scrollToOrderSection}
        scrollToReviewSection={scrollToReviewSection}
        data={data}
      />
      <div>
        <FeatureSection data={data} />
      </div>
      <div ref={reviewsRef}>
        {data.testimonials.length > 0 ? (
          <TestimonialSection data={data} />
        ) : null}
      </div>
      <div ref={orderRef}>
        <ApplyNowSection data={data} />
      </div>

      <div className={classes.FloatingChat}>
        <a
          href={
            data.id === "bentonite-cat-litter"
              ? "https://wa.me/919899113898?text=Hi%21%20I%20want%20to%20place%20an%20order%20for%20Bentonite%20Cat%20Litter"
              : "https://wa.me/919899113898?text=Hi%21%20I%20want%20to%20place%20an%20order%20for%20Smart%20Litter%20Pal"
          }
          title="WhatsApp Chat"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            className={classes.WhatsAppIcon}
            src={WhatsAppIcon}
            alt={"Whatsapp Chat Kipenzi"}
          />
        </a>
      </div>
    </main>
  );
};

export default ProductDetailPage;
