import React from "react";

import classes from "./TestimonialItem.module.css";
import Stars5 from "../../../../assets/icons/5-star.png";
import Stars4_5 from "../../../../assets/icons/4.5-star.png";

const TestimonialItem = ({ message, username, rating }) => {
  const ratingIcon = (rating) => {
    switch (rating) {
      case 5:
        return Stars5;
      default:
        return Stars4_5;
    }
  };
  return (
    <div className={classes.TestimonialWrapper}>
      <div className={classes.TestimonialItem}>
        <img
          className={classes.RatingBar}
          src={ratingIcon(rating)}
          alt="Kipenzi Cat Litter Rating"
        />
        <p className={classes.TestimonialText}>{message}</p>
        {/* <p className={classes.TestimonialUser}>{username}</p> */}
      </div>
    </div>
  );
};

export default TestimonialItem;
