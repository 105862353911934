import React from "react";
import FeatureItem from "./FeatureItem";

import classes from "./FeatureSection.module.css";

const FeatureSection = ({data}) => {
  return (
    <section className={classes.FeatureSectionWrapper}>
      <h2 className={classes.MainHeading}>Feature Highlights</h2>
      <img
        className={[classes.Illustration, classes.MobileOnly].join(" ")}
        src={data && data.productFeatureImage}
        alt="Kepinzi Automatic Cat Litter Machine"
      />
      <div className={classes.FeatureItemWrapper}>
        <div>
          {
            data && data.productFeatures && data.productFeatures.slice(0, 2).map((item, key) => (
              <FeatureItem
                icon={item.icon}
                heading={item.heading}
                description={item.description}
              />
            ))
          }
        </div>
        <div>
          {
            data && data.productFeatures && data.productFeatures.slice(2, 5).map((item, key) => (
              <FeatureItem
                icon={item.icon}
                heading={item.heading}
                description={item.description}
              />
            ))
          }
        </div>
      </div>
      <div
        className={[classes.IllustrationWrapper, classes.DesktopOnly].join(" ")}
      >
        <img
          className={classes.Illustration}
          src={data && data.productFeatureImage}
          alt="Kepinzi Automatic Cat Litter Machine"
        />
      </div>
    </section>
  );
};

export default FeatureSection;
