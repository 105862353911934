import React from "react";
import classes from "./PrivacyPolicyPage.module.css";

const PrivacyPolicyPage = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className={classes.MainContainer}>
      <h1 className={classes.MainHeading}>Privacy Policy</h1>
      <p>
        This Website ("<b>www.kipenzistore.com</b>") is owned and operated by{" "}
        <b>Vikram Kalia HUF</b> (hereinafter referred to as “<b>Owner</b>” or “
        <b>Vikram Kalia HUF</b>”) a legal entity recognised under the Indian
        Companies Act, 1956 and having its Corporate Office Vikram Kalia HUF,
        1st Floor, Plot no 61, Chandra Park, Opposite NSIT Dwarka, New
        Delhi-110078, India (hereinafter referred to as the “HUF”) and is the
        sole owner, operator, author and publisher of the Website.
      </p>
      <p>
        We recommend that before using any of the services on Kipenzistore, you
        must read and agree to our Privacy Policy as well as our terms of use.
      </p>
      <p>
        By accessing or using Kipenzistore or brand Kipenzi in any manner,
        including, but not limited to, visiting or browsing on Kipenzistore or
        contributing content or other materials to Kipenzistore, you agree to be
        bounded by our privacy policy.
      </p>
      <p>
        This policy amongst describes: (i) the type of information that the
        Company may collect from you when you access or use its websites,
        application; and (ii) the Company’s practices for collecting, using,
        maintaining, protecting and disclosing that information.
      </p>
      <p>
        We encourage you to read this policy carefully to understand the
        Companies Policies and Practices regarding your information.
      </p>
      <p>
        We reserve the right to make changes to this Privacy Policy at any time
        and for any reason. We will alert you about any changes by updating the
        “Last updated” date of this Privacy Policy. You are encouraged to
        periodically review this Privacy Policy to stay informed of updates. You
        will be deemed to have been made aware of, will be subject to, and will
        be deemed to have accepted the changes in any revised Privacy Policy by
        your continued use of the Application after the date such revised
        Privacy Policy is posted.
      </p>
      <h3>Interpretation and Definitions</h3>
      <p>
        Interpretation: The words of which the initial letter is capitalized
        have meanings defined under the following conditions. The following
        definitions shall have the same meaning regardless of whether they
        appear in singular or in plural.
      </p>

      <p>Definitions for the purposes of this Privacy Policy:</p>
      <ul>
        <li>
          "Account" means a unique account created voluntary by you to access
          our Service or parts of our Services.
        </li>
        <li>
          "Application" means the software program provided by the Company
          downloaded by You on any electronic device, named Kipenzi or Vikram
          Kalia HUF
        </li>
        <li>“Website” means www.kipenzistore.com</li>
        <li>
          "Cookies” We use cookies, permissions and other trackers in our
          website and mobile applications that are used to collect and process
          data about you so we can provide you a better online experience as
          well as improve our services in terms of personalised offers.
        </li>
      </ul>
      <p>
        Additionally, you may encounter “cookies” or other similar files on
        certain pages of the website or app that are placed by third parties.
        The Company does not control the use of cookies by third parties.
      </p>
      <ul>
        <li>
          "Device" means any device that can access the Service such as a
          computer, a cell phone or a digital tablet.
        </li>
        <li>
          "Personal Data" is any information that relates to an identified or
          identifiable individual.{" "}
        </li>
        <li>
          "Usage Data" refers to data collected automatically, either generated
          by the use of the Service or from the Service infrastructure itself
          (for example, the duration of a page visit).{" "}
        </li>
        <li>"You" means the individual accessing or using the Platform </li>
      </ul>
      <h3>COLLECTION OF DATA</h3>
      <p>We may collect information about you in a variety of ways.</p>
      <h4>TYPES OF DATA COLLECTED</h4>
      <p>
        <b>Personal Data:</b>
      </p>
      <p>
        Demographic and other personally identifiable information (such as your
        name, email address, phone number, address and your pet details) that
        you voluntarily give to us while installing the Application, online
        chatting with our representatives, usage of services such as spa
        booking, products view, selection of products, purchase of products,
        sharing feedback.{" "}
      </p>
      <p>
        If you choose to share data about yourself via your profile, online
        chat, or other interactive areas of the Application, please be advised
        that all data you disclose in these areas is private.
      </p>
      <p>
        <b>Financial Data</b>
      </p>
      <p>
        You may from time to time choose to provide payment related financial
        information (credit card, debit card, bank account details, name,
        billing address, shipping address, phone number etc.) on the Platform
        and Third-Party Sources. The Company will not use your financial
        information for any purpose other than to complete a transaction with
        you and sending you the relevant offers from the Partner Bank
      </p>
      <p>
        <b>Data from Social Network</b>
      </p>
      <p>
        User information from social networking sites, such as [ Facebook,
        Instagram, Pinterest, Twitter etc], including your name, your social
        network username, location, gender, birth date, email address, profile
        picture, and public data for contacts, if you connect your account to
        such social networks. This information may also include the contact
        information of anyone you invite to use and/or join the Application.
      </p>
      <p>
        <b>Location Data</b>
      </p>
      <p>
        We may request access or permission to and track location-based
        information from your mobile device, either continuously or while you
        are using the Application, to provide location-based services. If you
        wish to change our access or permissions, you may do so in your device's
        settings.
      </p>
      <p>
        <b>Push Notifications</b>
      </p>
      <p>
        We may request to send you push notifications regarding your account. If
        you wish to opt-out from receiving these types of communications, you
        may turn them off in your device’s settings
      </p>
      <p>
        <b>Device Information</b>
      </p>
      <p>
        We may need your device permission to get information about your device,
        like OS (operating System) name, mobile network, device identifier etc.
        Based on these inputs, we intend to optimize your experience and thereby
        serve you better.
      </p>
      <p>
        <b>Data From Contests, Giveaways, and Surveys</b>
      </p>
      <p>
        Personal and other information you may provide when entering contests or
        giveaways and/or responding to surveys.
      </p>
      <h3>Data Usage</h3>
      <p>
        Usage Data is collected automatically when using the platform. Usage
        Data may include information such as Your Device's Internet Protocol
        address (e.g., IP address), browser type, browser version, the pages of
        our Service that You visit, the time and date of your visit, unique
        device identifiers and other diagnostic data. When You access the
        Service by or through a mobile device, we may collect certain
        information automatically, including, but not limited to, the type of
        mobile device You use, your mobile device unique ID, the IP address of
        Your mobile device, Your mobile operating system, the type of mobile
        Internet browser You use, unique device identifiers and other diagnostic
        data. We may also collect information that Your browser and application
        sends whenever You visit the platform.
      </p>
      <h3>Use of your Data</h3>
      <p>
        Having accurate information about you permits us to provide you with a
        smooth, efficient, and customized experience. Specifically, we may use
        information collected about you for the following purposes:
      </p>
      <ul>
        <li>
          To create and manage Your Account: For creating and giving you access
          to your registered account on Platform.
        </li>
        <li>
          For the performance of a contract: the development, compliance and
          undertaking of the purchase contract for the products, items or
          services You have purchased or of any other contract with Us through
          the Service.{" "}
        </li>
        <li>
          To contact You: To contact You by email, telephone calls, SMS, or
          other equivalent forms of electronic communication, such as a mobile
          application's push notifications regarding updates or informative
          communications related to the functionalities, products or contracted
          services, including the security updates, when necessary or reasonable
          for their implementation.
        </li>
        <li>
          To provide You with news, special offers and general information about
          other goods, services and events which we offer that are similar to
          those that you have already purchased or enquired about unless You
          have opted not to receive such information.
        </li>
        <li>To Process payments and refunds.</li>
        <li>
          To manage Your requests: To attend and manage Your requests to Us.
        </li>
        <li>
          For other purposes: We may use Your information for other purposes,
          such as data analysis, identifying usage trends, determining the
          effectiveness of our promotional campaigns and to evaluate and improve
          our Service, products, and your experience.
        </li>
      </ul>
      <h3>Retention of Your Data</h3>
      <p>
        We will retain your information and any data for the period necessary to
        fulfil the purposes outlined in this Privacy Policy unless a longer
        retention period is required or permitted under the applicable law.
      </p>
      <h3>Disclosure of your Data</h3>
      <p>
        We may share information we have collected about you in certain
        situations. Your information may be disclosed as follows:
      </p>
      <h4>By Law or to Protect Rights</h4>
      <p>
        We believe the release of data about you is necessary to respond to
        legal process, to investigate or remedy potential violations of our
        policies, or to protect the rights, property, and safety of others, we
        may share your information as permitted or required by any applicable
        laws, rules, or regulations.
      </p>
      <h4>Third-Party Service Providers</h4>
      <p>
        We may share your information with third parties that perform services
        for us as per our agreed terms including payment processing and
        financial information.
      </p>
      <h4>Other Third Parties</h4>
      <p>
        We may share your information with third parties for the purpose of
        conducting general business analysis. We may also share your information
        with such third parties for marketing purposes, as permitted by law.
      </p>
      <h3>CHILDREN’S PRIVACY</h3>
      <p>
        Our Platform is not directed towards minors. We do not knowingly collect
        Personal Information from any User who is under 18 (Eighteen) years
      </p>
      <h3>TRACKING TECHNOLOGIES</h3>
      <p>
        <b>Cookies</b>
      </p>
      <p>
        We use cookies and other similar technologies (such as pixel tags and
        clear gifs) to store certain types of information each time you visit
        platform. You may choose to decline cookies, but this would disable the
        scope of experiencing the entire feature of the website and application.
        Hence, we recommend that you leave them turned on.{" "}
      </p>

      <p>
        <b>Internet Based Advertising</b>
      </p>
      <p>
        Additionally, we may use third-party software to serve ads on the
        platform, implement email marketing campaigns, and manage other
        interactive marketing initiatives. This third-party software may use
        cookies or similar tracking technology to help manage and optimize your
        online experience with us.
      </p>
      <h3>CONSENT</h3>
      <p>
        By using the Platform and/ or by providing your information, you consent
        to the collection and use of the information you disclose on the
        Platform in accordance with this Privacy Policy, including but not
        limited to your consent for sharing your information as per this privacy
        policy.
      </p>
      <h3>DATA SECURITY</h3>
      <p>
        We are committed to protecting your Data in our custody. We take
        reasonable steps to ensure appropriate physical, technical and
        managerial safeguards are in place to protect your Data from
        unauthorized access, alteration, transmission and deletion.
      </p>

      <h3>OPTIONS REGARDING YOUR DATA</h3>
      <p>
        You may at any time review or change the information in your account or
        terminate your account by:
      </p>
      <ul>
        <li>Logging into your account settings and updating your account</li>
        <li>Contacting us using the contact information provided below</li>
      </ul>
      <p>
        Upon your request to terminate your account, we will deactivate or
        delete your account and information from our active databases. However,
        some information may be retained in our files to prevent fraud,
        troubleshoot problems, assist with any investigations, enforce our Terms
        of Use and/or comply with legal requirements.
      </p>
      <h3>EMAILS AND COMMUNICATIONS</h3>
      <p>
        If you no longer wish to receive correspondence, emails, or other
        communications from us, you may opt-out by:
      </p>
      <ul>
        <li>
          Noting your preferences at the time you register your account with the
          Application
        </li>
        <li>
          Logging into your account settings and updating your preferences
        </li>
        <li>Contacting us using the contact information provided below</li>
      </ul>
      <h3>CONTACT US</h3>
      <p>
        If there are any questions regarding this privacy policy you may contact
        us using the information on the shared below:
      </p>
      <p>
        If you wish to report a breach of the Privacy Policy, you may contact
        the designated Grievance Officer of the Company at: Corporate Office:
        Vikram Kalia HUF, 1st Floor, Plot no 61, Chandra Park, Opposite NSIT
        Dwarka, New Delhi-110078
      </p>
      <p>Phone Number: - 91-7011559177</p>
      <p>
        Email Address:{" "}
        <a href="mailto:info@kipenzistore.com">info@kipenzistore.com</a>
      </p>

      <p>Updated on 02-03-2024</p>
    </main>
  );
};

export default PrivacyPolicyPage;
