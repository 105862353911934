import React from "react";
import classes from "./TermsPage.module.css";

const TermsPage = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className={classes.MainContainer}>
      <h1 className={classes.MainHeading}>Terms of Use</h1>
      <h3>GENERAL</h3>
      <p>
        This Website (<b>"www.kipenzistore.com"</b>) is owned and operated by{" "}
        <b>Vikram Kalia HUF</b> (hereinafter referred to as <b>“Owner”</b> or{" "}
        <b>“Vikram Kalia HUF”</b>) a legal entity recognised under the Indian
        Companies Act, 1956 and having its Corporate Office Vikram Kalia HUF,
        1st Floor, Plot no 61, Chandra Park, Opposite NSIT Dwarka, New
        Delhi-110078, India (hereinafter referred to as the “HUF”) and is the
        sole owner, operator, author and publisher of the Website.
      </p>
      <p>
        We recommend that before using any of the services on Kipenzistore, you
        must read and agree to these Terms of Use as well as our Privacy Policy.
      </p>
      <p>
        By accessing or using Kipenzistore or brand Kipenzi in any manner,
        including, but not limited to, visiting or browsing on Kipenzistore or
        contributing content or other materials to Kipenzistore, you agree to be
        bound by these Terms of Use.
      </p>
      <h3>ACCEPTANCE OF TERMS AND CONDITIONS</h3>
      <p>
        These Terms of Use (hereinafter referred to as "
        <b>Terms and Conditions</b>" or "<b>T&C</b>" or "<b>Terms</b>" or "
        <b>Agreement</b>") along with any other Policy or Statement or
        Information that may be placed on this website (hereinafter referred to
        as “Vikram Kalia HUF” or “Kipenzi” or “Website” or “We” or “Us”), as
        modified or amended from time to time, are a binding contract between
        the Vikram Kalia HUF and You (hereinafter referred to as "<b>You</b>" or
        "<b>End User</b>" or "<b>Your</b>" or "<b>Buyer</b>" or "<b>Customer</b>
        ")
      </p>
      <p>
        If you visit, use, or shop at the site or any future site operated by
        the Vikram Kalia HUF, you accept these Terms and Conditions. In
        addition, when you use any current or future services of the Vikram
        Kalia HUF or visit or purchase from any business affiliated with the
        Vikram Kalia HUF or third-party vendors, whether or not included in the
        site, you also will be subject to the guidelines and conditions
        applicable to such service or merchant. If these conditions are
        inconsistent with such guidelines and conditions, such guidelines and
        conditions will control.
      </p>
      <p>
        You by subscribing to or using any of our products or services, you
        agree that you have read, understood and are bound by the Terms and
        Conditions, regardless of how you subscribe to or use our products or
        services. If you do not want to be bound by the terms, you must not
        subscribe to or use our services.
      </p>
      <h3>MODIFICATION</h3>
      <p>
        Vikram Kalia HUF reserves the right to change, modify, adjust, vary,
        amend or alter all or any of its Terms of Use at any time and at its
        sole discretion. All such changes, modifications, adjustments,
        amendments and alterations shall be duly notified by on Kipenzistore
        however, it is the responsibility of the User to keep himself/herself
        updated regarding such modifications. Vikram Kalia HUF shall in no case
        be held liable in respect of such modifications. The User agrees to
        abide by all applicable guidelines, policies, rules, terms and
        conditions for availing the Services on Kipenzistore, which may change
        from time to time.
      </p>
      <h3>PROHIBITIONS</h3>
      <p>
        Vikram Kalia HUF grants you a limited license to access and make
        personal use of the website and services.
      </p>
      <p>
        The following actions will be considered as misuse of the website, and
        are thus prohibited:
      </p>
      <ol>
        <li>
          You are not allowed to reproduce, modify, distribute, display any
          portion, publish any content or make any commercial use of any of the
          information provided in this website.
        </li>
        <li>
          You shall not distribute in any form, any information, or other
          material that violates, infringes the copyrights, patents, trademarks,
          trade secrets, logo or other proprietary rights of Kipenzistore or
          brand Kipenzi.
        </li>
        <li>
          You are not allowed to republish, archive or retain any content on the
          internet, intranet, extranet, database, archive or compilation. You
          are not allowed to use any content for commercial use.
        </li>
        <li>
          You agree not to decompile, reverse engineer or disassemble any
          software or other products or processes accessible through the
          website, and not to insert any code or product or manipulate the
          content in any way that affects the user's experience.
        </li>
        <li>
          You are not allowed to use the website in any manner that is illegal
          or impairs the operation of the website or its availability or usage
          by others.
        </li>
        <li>
          You further agree not to use any data mining, bugs, viruses, worms,
          trap doors, web crawlers, robots, cancel bots, spiders, Trojan horses,
          other harmful code of properties or any data gathering or extraction
          method in connection with your use of the website.
        </li>
        <li>
          You are not allowed to make any use of the website for the benefit of
          another business.
        </li>
        <li>
          You are not allowed to post unsolicited promotional or advertising
          content.
        </li>
        <li>
          We hereby hold no liability to any sort of damage or harm caused to
          your software, data or computer device by downloading content from
          this website.
        </li>
      </ol>

      <h3>ELIGIBILITY</h3>
      <p>
        Use of this website is available only to persons who can form legally
        binding contracts under Indian Contract Act, 1872. Persons who are
        "incompetent to contract" within the meaning of the Indian Contract Act,
        1872 including minors, un-discharged insolvents etc. are not eligible to
        use this Website.
      </p>
      <p>
        The Service is not available to minors under the age of 18 or to any
        users suspended or removed from the system by Vikram Kalia HUF for any
        reason. If you are a minor i.e. under the age of 18 years, you shall not
        purchase any items on the Website. As a minor if you wish to purchase an
        item on the Website, such a purchase may be made by your legal guardian
        or parents.
      </p>
      <p>
        Users may not have more than one account. Maintaining more than one
        account by a user shall amount to fraudulent act on part of the user and
        attract actions against such users. Additionally, users are prohibited
        from selling, trading, or otherwise transferring your Vikram Kalia HUF
        account to another party. If you do not qualify, you may not use the
        Service or the Site.
      </p>
      <p>
        The Vikram Kalia HUF owns no responsibility in any manner over any
        dispute arising out of transactions by any third party using your
        account/e-mail provided by you to the Vikram Kalia HUF or payments made
        by your credit card by any third party.
      </p>
      <p>
        In consideration of your use of the Site, you represent that you are of
        legal age to form a binding contract and are not a person barred from
        receiving services under the laws as applicable in India. You also agree
        to provide true, accurate, current and complete information about
        yourself as prompted by the Site's registration form. If you provide any
        information that is untrue, inaccurate, not current or incomplete (or
        becomes untrue, inaccurate, not current or incomplete), or Vikram Kalia
        HUF has reasonable grounds to suspect that such information is untrue,
        inaccurate, not current or incomplete, Vikram Kalia HUF on
        kipenzistore.com has the right to suspend or terminate your account and
        refuse any and all current or future use of the Site (or any portion
        thereof). If you use the Site, you are responsible for maintaining the
        confidentiality of your account and password including cases when it is
        being used by any of your family members, friends or relatives, whether
        a minor or an adult. You further agree to accept responsibility for all
        transactions made from your account and any dispute arising out of any
        misuse of your account, whether by any family member, friend, relative,
        any third party or otherwise shall not be entertained by the Vikram
        Kalia HUF. Because of this, we strongly recommend that you exit from
        your account at the end of each session. You agree to notify Vikram
        Kalia HUF immediately of any unauthorized use of your account or any
        other breach of security. Vikram Kalia HUF reserves the right to refuse
        service, terminate accounts, or remove or edit content in its sole
        discretion.
      </p>
      <p>
        If you are a business entity, you represent that you are duly authorised
        by the business entity to accept these terms and conditions and you have
        the authority to bind that business entity to these terms and
        conditions.
      </p>
      <h3>PRODUCT INFORMATION, PRICING AND PROMOTIONAL DISCOUNTS</h3>
      <p>
        While Vikram Kalia HUF on kipenzistore.com strives to provide accurate
        product and pricing information, typographical errors may occur. In the
        event that a product is listed at an incorrect price or with incorrect
        information due to an error in pricing or product information, Vikram
        Kalia HUF may, at its discretion, either contact you for instructions or
        cancel your order and notify you of such cancellation. kipenzistore.com
        will have the right to modify the price of the product and contact you
        for further instructions using the e-mail address or telephone number
        provided by you during the time of registration or cancel the order and
        notify you of such cancellation.
      </p>
      <p>
        The price of all products, delivery charges and any other applicable
        charges are displayed in Indian Rupees (INR). All prices are current at
        time of display, but these prices are subject to change without notice.
      </p>

      <p>
        Vikram Kalia HUF on Kipenzistore.com has made every effort to display as
        accurately as possible the colours of our products that appear on the
        website. Further, we have ensured that the measurements, information and
        description for products furnished on the site are best calculated and
        stated to accuracy and true to its dimensions. However, due to the
        inherent characteristics of certain materials, actual measurements of
        individual items might vary slightly.
      </p>
      <p>
        We offer you promotional discount codes that are applicable on the
        purchases made on this website. These discount codes can be applicable
        on all or certain specified products. Please note that use of only one
        discount code is permissible per order. You cannot use a discount code
        if an order is already placed.
      </p>
      <p>
        As a condition of purchase, the Site requires your permission to send
        you administrative and promotional emails/calls. We will send you
        information regarding your account activity and purchases, as well as
        updates about our products and promotional offers. We shall have no
        responsibility in any manner whatsoever regarding any promotional
        emails/calls/SMS/MMS sent to you. The offers made in those promotional
        emails/calls/SMS/MMS shall be subject to change at the sole discretion
        of the Vikram Kalia HUF and the Vikram Kalia HUF owes no responsibility
        to provide you any information regarding such change.
      </p>
      <p>
        You are required to create an account in order to purchase any product
        from the Site. This is required so we can provide you with easy access
        to print your orders and view your past purchases.
      </p>
      <p>
        The Site takes no responsibility for the services or products that are
        sold or supplied by third party vendors. The Vikram Kalia HUF makes no
        warranty to their end users for the quality, safety, usability, or other
        aspect of a product or service that is supplied by a Merchant.
      </p>
      <h3>TAXES</h3>
      <p>
        You shall be responsible for payment of all fees/costs/charges
        associated with the purchase of products from us and you agree to bear
        any and all applicable taxes including but not limited to VAT/CST,
        service tax, GST, duties and cesses etc.
      </p>
      <h3>ERRORS, INACCURACIES, AND OMISSIONS</h3>
      <p>
        There may be information on our site that contains typographical errors,
        inaccuracies, or omissions that may relate to product descriptions,
        pricing, promotions, offers, transit times and availability. We reserve
        the right to correct any errors, inaccuracies or omissions and to change
        or update information without prior notice (including after you have
        submitted your order).
      </p>
      <p>
        In the event a product is listed at an incorrect price or with incorrect
        information due to a typographical error or error in pricing or product
        information, Kipenzistore shall have the right to refuse or cancel any
        orders placed for the product listed at the incorrect price whether or
        not the order has been confirmed and your credit card charged. If your
        credit card has already been charged for the purchase and your order is
        cancelled, Kipenzistore shall immediately issue credit in the amount of
        the charge.
      </p>
      <h3>CONTRACT FOR SALE OF GOODS</h3>
      <p>
        The parties to the contract are Kipenzistore ("the supplier") and you
        ("the purchaser"). The terms and conditions of this website constitute
        the terms and conditions of the contract. By placing an order you
        confirm that you have read the terms and conditions and accept them.
      </p>
      <p>
        Payment must be made at the time the products are ordered (unless you
        opt for the Cash-On-Delivery option). When you place an order you will
        be automatically redirected to the secure Razorpay or PayPal website for
        you to complete payment. Kipenzistore does not keep a record of your
        credit card details.
      </p>
      <p>
        Kipenzistore retains the right to accept or deny any offer made. This
        may be due to the availability of any product, or for any other reason
        that may affect our ability to supply the products ordered. No contract
        is formed until we communicate acceptance of your order.
      </p>
      <p>
        The electronic contract shall have the same legal force and effect as a
        written contract signed by you.
      </p>
      <h3>INTELLECTUAL PROPERTY SOFTWARE AND CONTENT</h3>
      <p>
        Kipenzistore owns all the content available on this website, including,
        the text, graphics and copyright works. Kipenzistore is the exclusive
        owner of all rights in the compilation, design and layout of this
        website.
      </p>
      <p>
        You may not copy any content from this website without the prior written
        consent from Kipenzistore. Modification, misuse, translation or creation
        of derivative work on the basis of website content is highly prohibited.
      </p>
      <p>
        Vikram Kalia HUF grants you a limited license to access and make
        personal use of the Site and the Service. This license does not include
        any downloading or copying of account information for the benefit of
        another vendor or any other third party; caching, unauthorized hypertext
        links to the Site and the framing of any Content available through the
        Site uploading, posting, or transmitting any content that you do not
        have a right to make available (such as the intellectual property of
        another party); uploading, posting, or transmitting any material that
        contains software viruses or any other computer code, files or programs
        designed to interrupt, destroy or limit the functionality of any
        computer software or hardware or telecommunications equipment; any
        action that imposes or may impose (in Vikram Kalia HUF sole discretion)
        an unreasonable or disproportionately large load on Vikram Kalia HUF
        infrastructure; or any use of data mining, robots, or similar data
        gathering and extraction tools. You may not bypass any measures used by
        Vikram Kalia HUF to prevent or restrict access to the Site. Any
        unauthorized use by you shall terminate the permission or license
        granted to you by Vikram Kalia HUF.
      </p>
      <h3>LINKS TO OTHER SITES</h3>
      <p>
        This website may contain links to third party websites. Any outside
        links are provided only as a convenience. Your use of outside links is
        at your sole risk. Links from the website do not constitute Kipenzistore
        endorsement of any third party, its website, or its goods or services.
        Kipenzistore is not responsible for any outside sites, services or other
        materials linked to or from the website, and disclaims all liability for
        any injury you may experience by using such materials.
      </p>
      <h3>GUARANTEES AND WARRANTIES</h3>
      <p>
        To the extent permitted by law, Kipenzistore excludes all warranties,
        representations and guarantees (whether express, implied or statutory),
        and we will not be liable for any damages, losses or expenses, or
        indirect losses or consequential damages of any kind, suffered or
        incurred by you in connection with your access to or use of this website
        or the content on or accessed through it.
      </p>

      <p>
        This disclaimer of liability also applies to any damages or injury
        caused by any failure of performance, error, omission, interruption,
        deletion, defect, delay in operation or transmission, computer virus,
        communication line failure, theft or destruction or unauthorised access
        to, alteration of, or use of record, whether for breach of contract,
        tortuous behaviour, negligence, or under any other cause of action.
      </p>
      <h3>PRIVACY</h3>
      <p>
        Please review our Privacy Policy, which also governs your visit to
        kipenzistore.com, to understand our practices. The personal information
        / data provided to us by you during the course of usage of
        kipenzistore.com will be treated as strictly confidential and in
        accordance with the Privacy Policy and applicable laws and regulations.
        If you object to your information being transferred or used, please do
        not use the website.
      </p>
      <h3>ACCOUNT INFORMATION</h3>
      <p>
        Kipenzistore may assign you a password and account for identification,
        to enable you to access and use certain portions of this website. Each
        time you use a password or identification, you will be deemed to be
        authorized to access and use the website in a manner consistent with the
        terms and conditions of this agreement.
      </p>
      <p>
        Kipenzistore has no obligation to investigate the authorization or
        source of any such access or use of the website. You are solely
        responsible for protecting the security and confidentiality of the
        password and identification assigned and for restricting access to your
        computer, and you agree to accept responsibility for all activities that
        occur under your account or password.
      </p>

      <p>
        You shall immediately notify Kipenzistore of any unauthorized use of
        your password or identification or any other breach or threatened breach
        of this website's security.
      </p>
      <p>
        You will be solely responsible for all access to and use of this site by
        anyone using this password and identification whether or not such access
        to and use of this site (including all obligations, communications and
        transmissions) is actually authorized by you.
      </p>
      <p>
        Kipenzistore reserves the right to refuse service, terminate accounts,
        and/or cancel orders at its discretion, including, without limitation,
        if Kipenzistore believes that the conduct by the customer violates
        applicable law or is harmful to website.
      </p>
      <h3>CANCELLATION POLICY</h3>
      <h4>a) Cancellations by Kipenzistore</h4>
      <p>
        Kipenzistore has a right to cancel or refuse the order for any reason.
        Some situations that may result in your order being cancelled include
        limitations on quantities available for purchase, inaccuracies or errors
        in product or pricing information, or problems identified by our credit
        and fraud avoidance department. We may also require additional
        verifications or information before accepting any order. We will contact
        you if all or any portion of your order is cancelled or if additional
        information is required to accept your order. If your order is cancelled
        after your credit card has been charged, the said amount will be
        reversed back in your Card Account.
      </p>
      <h4>b) Cancellations by You</h4>
      <p>
        In case of requests for order cancellations, Kipenzistore reserves the
        right to accept or reject requests for order cancellations for any
        reason. For you to cancel an order, there is a time period of 24 hours
        from the time you place the order. As part of usual business practice,
        if we receive a cancellation notice and the order has not been processed
        / approved by us, we shall cancel the order and refund the entire amount
        with-in 15-20 working days.
      </p>
      <p>
        We will not be able to cancel orders that have already been processed.
      </p>
      <p>
        Kipenzistore has the full right to decide whether an order has been
        processed or not. You agree not to dispute the decision made by
        Kipenzistore and accept Kipenzistore decision regarding the
        cancellation.
      </p>

      <h3>FRAUDS</h3>
      <p>
        Kipenzistore reserves the right to recover the cost of goods, collection
        charges and other expenses that may occur from persons for using the
        Site fraudulently. Kipenzistore reserves the right to initiate legal
        proceedings against such persons for fraudulent use of the Site and any
        other unlawful acts or omissions in breach of these terms and
        conditions.
      </p>
      <h3>REDEEM VOUCHERS AND PROMOTIONAL CODES</h3>
      <p>
        There are limited period vouchers and promotional codes which can be
        issued or cancelled by Kipenzistore as part of any campaign or scheme.
        The terms for these campaigns will be decided by Kipenzistore at the
        time of the campaign and these should be acceptable to you.
      </p>
      <h3>DISCLAIMER</h3>
      <p>
        You acknowledge and undertake that you are accessing the services on the
        site and transacting at your own risk and are using your best and
        prudent judgment before entering into any transactions through
        Kipenzistore. If you are dissatisfied with the Site, any Contents, or
        any of these Terms and conditions, we would like to hear from you.
      </p>
      <p>
        However, your only legal remedy is to stop using the website.
        Kipenzistore does not warrant your use of the Site.
      </p>
      <h3>INDEMNIFICATION</h3>
      <p>
        You shall indemnify and hold harmless Kipenzistore, its owner, licensee,
        affiliates, subsidiaries, group companies (if any) and their respective
        officers, directors, agents, and employees, from any claim or demand, or
        actions including reasonable attorneys' fees, made by any third party or
        penalty imposed due to or arising out of Your breach of this Terms of
        Use, privacy Policy and other Policies, or Your violation of any law,
        rules or regulations or the rights (including infringement of
        intellectual property rights) of a third party.
      </p>
      <h3>GOVERNING LAW, JURISDICTION AND DISPUTE RESOLUTION</h3>
      <p>
        Kipenzistore controls and operates this website from its Registered
        Office in New Delhi. These Terms of Use (and any further rules, polices,
        or guidelines incorporated by reference) shall be governed and construed
        in accordance with the laws of India.
      </p>
      <p>
        Any dispute arising under or relating to the terms, contents, your use
        of the website, or products or services purchased using the website or
        with Kipenzistore shall solely and to the exclusion of all other courts
        be subject to the jurisdiction of the appropriate Courts situated in New
        Delhi, India alone. By using the website, you consent to the
        jurisdiction and venue of New Delhi courts with respect to any such
        dispute.
      </p>
      <h3>GRIEVANCE REDRESSAL</h3>
      <p>
        Any complaint or concern with regard to the Services, access, usage,
        content, comment or breach of the Terms of Use shall be addressed to the
        designated Grievance Officer of Kipenzistore. The complaint shall be
        registered through a phone call on the number provided below or by
        sending an email to the respective email ID as provided below. It shall
        be the endeavour of Kipenzistore to satisfactorily resolve and address
        the grievances at the earliest.
      </p>
      <p>Phone number: - 91-7011559177</p>
      <p>Email - info@kipenzistore.com</p>
      <h3>TERMINATION</h3>
      <p>
        Kipenzistore reserves the right to terminate the User’s access to
        Kipenzistore without any cause or notice. This may result in the
        forfeiture and destruction of all information associated with the User.
        In such an event, the Terms of Use shall come to an end. However, the
        Indemnification, Disclaimer, Governing Law/Jurisdiction and Dispute
        Resolution and Privacy Policy shall survive such termination.
      </p>
      <h3>ENTIRE AGREEMENT</h3>
      <p>
        These Terms of Use constitutes the complete agreement and sets forth the
        entire understanding of you and the Kipenzistore as to the subject
        matter of this Agreement. If any provision of this Agreement is found by
        any court of competent jurisdiction to be invalid or unenforceable, the
        invalidity of such provision shall not affect the other provisions of
        this Agreement, and all provisions not affected by such invalidity shall
        remain in full force and effect. The headings contained in this
        Agreement are for convenience of reference only and shall not affect the
        meaning and interpretation of this Agreement.
      </p>
      <p>
        By accepting these Terms of Use, the User agrees to have fully read and
        understood all the terms and conditions set out hereinabove.
      </p>

      <p>
        <b>
          Note: The Agreement is published in accordance with the provisions of
          Section 3 & Section 3-A of the Information Technology Act, 2000 that
          require an electronic record to be authenticated with an electronic
          signature and also in accordance with Rule 3(1) of the Information
          Technology (Intermediary Guidelines) Rules, 2011 that require
          publishing the rules and regulations, privacy policy and the user
          agreement for access-or-usage of the intermediary’s computer resource
          by any person.
        </b>
      </p>

      <p>Last Updated: 2-03-2024</p>
    </main>
  );
};

export default TermsPage;
