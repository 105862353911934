import React from 'react';
import classes from './AboutPage.module.css';

const AboutPage = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return(
        <main className={classes.MainContainer}>
            <h1 className={classes.MainHeading}>About Kipenzi</h1>
            <p>Welcome to Kipenzi, your go-to destination for all things pet-related. At Kipenzi, we have made it our mission to provide the utmost care and support for both pets and pet parents. With a deep-rooted belief that pets are not just animals, but cherished members of the family, we place them at the very center of everything we do.</p>
            <p>Our brand philosophy revolves around the unconditional bond between pets and their human companions. We understand that pets bring immeasurable joy, love, and companionship into our lives, and we believe they deserve the best care and attention in return. That's why we've dedicated ourselves to curating an extensive range of products and services that cater to the unique needs and well-being of these furry family members.</p>
            <p>At Kipenzi, we strive to offer pet parents a one-stop-shop experience, ensuring their convenience and peace of mind. From premium pet food and treats to cozy beds, stylish accessories, and innovative toys, our curated collection is designed to meet the diverse preferences and requirements of pets of all shapes and sizes. We carefully select each product, focusing on quality, safety, and sustainability, because we believe that our pets deserve nothing but the best.</p>
            <p>But our commitment goes beyond just providing products. We are here to support and guide pet parents on their journey, offering valuable resources and expert advice. Whether you're a first-time pet owner or an experienced caregiver, our team of pet enthusiasts is ready to assist you with any questions or concerns you may have. We want to empower you to make informed choices and ensure the well-being of your beloved companions.</p>
            <p>We also understand the importance of building a community of like-minded individuals who share a passion for pets. That's why we actively encourage interaction and engagement through our online platforms and social media channels. Join us to connect with other pet parents, share heartwarming stories, learn from each other's experiences, and celebrate the joys of pet ownership together.</p>
            <p>At Kipenzi, we envision a world where pets are cherished and nurtured, where the bond between pets and their loving families is strengthened, and where their happiness and well-being are prioritized. We invite you to embark on this journey with us, where pets and pet parents are at the heart of everything we do.</p>
            <p>Thank you for choosing Kipenzi. Together, we can create a brighter and more fulfilling life for our beloved pets.</p>
        </main>
    );
}

export default AboutPage;