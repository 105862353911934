import React from "react";

import classes from "./DarkButton.module.css";

const DarkButton = ({ fullWidth, click, icon, label }) => {
  const classNames = [classes.Button];
  if (fullWidth) {
    classNames.push(classes.FullWidth);
  }
  return (
    <button className={classNames.join(" ")} onClick={click}>
      {label}
      <span className={classes.ButtonIcon}>{icon}</span>
    </button>
  );
};

export default DarkButton;
