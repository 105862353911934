import React from "react";
import { PRODUCT_DATA } from "../../utils/AppData";
import classes from "./Homepage.module.css";
import { ROUTE_PATHS } from "../../utils/RoutePath";
import { Link } from "react-router-dom";

const Homepage = () => {
  const data = [...Object.values(PRODUCT_DATA)];
  return (
    <main className={classes.MainContainer}>
      <h1 className={classes.MainHeading}>All Products</h1>
      <div className={classes.ProductGrid}>
        {data &&
          data.map((item) => (
            <Link
              key={item.id}
              to={ROUTE_PATHS.PRODUCT_DETAILS + "/" + item.id}
              className={classes.ProductCardWrapper}
            >
              <div className={classes.ProductCard}>
                <img
                  className={classes.ProductImage}
                  src={item.mediaList[item.cardImageFromMediaList].src}
                  alt={item.productName}
                />
                <h3 className={classes.ProductName}>{item.productName}</h3>
              </div>
            </Link>
          ))}
      </div>
    </main>
  );
};

export default Homepage;
