import { MEDIA_TYPE } from "./Constants";

import DiagonalImage from "../assets/product-imgs/diagonal.png";
import ProductWithCatImage from "../assets/product-imgs/product-with-cat.png";
import EasyCleaningImage from "../assets/product-imgs/easy-cleaning.png";
import StructureOverviewImage from "../assets/product-imgs/structure-overview.png";
import TrackHealthImage from "../assets/product-imgs/track-health.png";
import LitterBoxFeatureImg from "../assets/product-imgs/product-feature-illustration.png";
import AutoCleaningIcon from "../assets/icons/settings.svg";
import OdorManagementIcon from "../assets/icons/litter.svg";
import HealthTrackingIcon from "../assets/icons/smartphone.svg";
import MultiCatsIcon from "../assets/icons/cat.svg";
import LitterBoxOrderImg from "../assets/product-imgs/order-img.png";

import ComingSoon from "../assets/coming-soon.jpg";
import LitterSandFeatureImg from "../assets/product-imgs/litter-feature-img.png";
import KipenziLitter1 from "../assets/product-imgs/kipenzi-litter-1.jpg";
import KipenziLitter2 from "../assets/product-imgs/kipenzi-litter-2.jpg";
import KipenziLitter3 from "../assets/product-imgs/kipenzi-litter-3.jpg";
import KipenziLitter4 from "../assets/product-imgs/kipenzi-litter-4.jpg";
import KipenziLitter5 from "../assets/product-imgs/kipenzi-litter-5.jpg";
import LitterNatural from "../assets/icons/litter-natural.png";
import LitterAbsorption from "../assets/icons/litter-rapid-absoption.png";
import LitterNoDust from "../assets/icons/litter-no-dust.png";
import LitterOdorFree from "../assets/icons/litter-odorfree.png";
import LitterSandOrderImg from "../assets/product-imgs/litter-img-order.png";

import IconHealthy from "../assets/icons/healthy.png";
import IconAntiFlea from "../assets/icons/anti-flea.png";
import IconSecureShield from "../assets/icons/secure-shield.png";
import IconHealing from "../assets/icons/healing.png";

import IconReject from "../assets/icons/reject.png";
import IconMite from "../assets/icons/mite.png";
import IconOdor from "../assets/icons/odor.png";
import IconHair from "../assets/icons/hair-care.png";

import IconSkinGeneration from "../assets/icons/skin-regeneration.png";
import IconHealed from "../assets/icons/healed.png";
import IconHydratedSkin from "../assets/icons/hydrated-skin.png";

import Shampoo8In1FeatureHighlight from '../assets/product-imgs/shampoo-8-in-1/feature-highlights.png';
import Shampoo8In1SlideMain from '../assets/product-imgs/shampoo-8-in-1/main-slide.png';
import Shampoo8In1Slide2 from '../assets/product-imgs/shampoo-8-in-1/slide-2.png';
import Shampoo8In1Slide3 from '../assets/product-imgs/shampoo-8-in-1/slide-3.png';
import Shampoo8In1Slide4 from '../assets/product-imgs/shampoo-8-in-1/slide-4.png';
import Shampoo8In1Slide5 from '../assets/product-imgs/shampoo-8-in-1/slide-5.png';

import ShampooPupKittenFeatureHighlight from '../assets/product-imgs/shampoo-pup-kitten/feature-highlights.png';
import ShampooPupKittenSlideMain from '../assets/product-imgs/shampoo-pup-kitten/main-slide.png';
import ShampooPupKittenSlide2 from '../assets/product-imgs/shampoo-pup-kitten/slide-2.png';
import ShampooPupKittenSlide3 from '../assets/product-imgs/shampoo-pup-kitten/slide-3.png';
import ShampooPupKittenSlide4 from '../assets/product-imgs/shampoo-pup-kitten/slide-4.png';
import ShampooPupKittenSlide5 from '../assets/product-imgs/shampoo-pup-kitten/slide-5.png';

import ShampooTickFleaFeatureHighlight from '../assets/product-imgs/shampoo-tick-flea/feature-highlights.png';
import ShampooTickFleaSlideMain from '../assets/product-imgs/shampoo-tick-flea/main-slide.png';
import ShampooTickFleaSlide2 from '../assets/product-imgs/shampoo-tick-flea/slide-2.png';
import ShampooTickFleaSlide3 from '../assets/product-imgs/shampoo-tick-flea/slide-3.png';
import ShampooTickFleaSlide4 from '../assets/product-imgs/shampoo-tick-flea/slide-4.png';
import ShampooTickFleaSlide5 from '../assets/product-imgs/shampoo-tick-flea/slide-5.png';

export const PRODUCT_DATA = {
  litterBox: {
    id: "smart-cat-litter-pal",
    preNameText: "India's First",
    productName: "Smart Litter Pal",
    productDesc:
      "Say hello to SmartLitterPal! It's the next-gen, super-rated, WiFi-connected, self-cleaning litter box for your adorable feline friends. 🐾",
    productRating: 4.8,
    cardImageFromMediaList: 0,
    mediaList: [
      {
        id: 1,
        type: MEDIA_TYPE.IMG,
        src: DiagonalImage,
        thumbnail: DiagonalImage,
        alt: "Kipenzi Automated Cat Litter Box",
      },
      {
        id: 2,
        src: ProductWithCatImage,
        thumbnail: ProductWithCatImage,
        alt: "Kipenzi Automated Cat Litter Box",
      },
      {
        id: 3,
        src: EasyCleaningImage,
        thumbnail: EasyCleaningImage,
        alt: "Kipenzi Automated Cat Litter Box",
      },
      {
        id: 4,
        src: StructureOverviewImage,
        thumbnail: StructureOverviewImage,
        alt: "Kipenzi Automated Cat Litter Box",
      },
      {
        id: 5,
        src: TrackHealthImage,
        thumbnail: TrackHealthImage,
        alt: "Kipenzi Automated Cat Litter Box",
      },
    ],
    aboutProduct: [
      {
        title: "App remote intelligent control",
        description:
          "App intelligently controls the operations of the device and timely understands cat toilet information",
      },
      {
        title: "Quick disassembly and easy cleaning",
        description:
          "You can quickly remove the barrel for water washing and cleaning.",
      },
      {
        title: "Suitability",
        description:
          "Suitable for cats (1kgs - 8kgs) It is recommended to use smart litter pal on manual for kittens less than 3 months and lactating cats.",
      },
      {
        title: "Dimensions",
        description: "48cm (W) x 50cm (H) x 51cm (D)",
      },
    ],
    additionalStats: {
      oneDesktop: "20,000+ cats love it",
      oneMobile: "20k+ cats love it",
      twoDesktop: "100,000+ happy pet parents",
      twoMobile: "100k+ happy pet parents",
    },
    productFeatureImage: LitterBoxFeatureImg,
    productFeatures: [
      {
        icon: AutoCleaningIcon,
        heading: "Auto-Cleaning",
        description:
          "Automatically removes waste after every use. No need to scoop the litter yourself.",
      },
      {
        icon: OdorManagementIcon,
        heading: "Odor management",
        description:
          "Automatically moves the clumps into a sealed drawer which helps reduce bad odor.",
      },
      {
        icon: HealthTrackingIcon,
        heading: "Health Tracking",
        description:
          "Monitor cat weight, track bathroom habits, and receive waste alerts with Smart Life App.",
      },
      {
        icon: MultiCatsIcon,
        heading: "Multi-Cat Friendly",
        description:
          "Single smart Litter Pal serves up to 5 cats, removing the need for multiple boxes.",
      },
    ],
    testimonials: [
      {
        name: "Private Account",
        rating: 5,
        message:
          "The product is perfect and my cat already loved it after only one day! Definitely recommended.",
      },
      {
        name: "Private Account",
        rating: 5,
        message:
          "My cat used it already twice. He is a bit nervous when it's in the cleaning phase, but I think he will get used to it. For now, I keep the old and new toilets next to each other. Overall - very satisfied. Hope my cat will be satisfied as well with new fresh and clean litter for a long time!",
      },
      {
        name: "Private Account",
        rating: 5,
        message:
          "Very good quality product, my kittens got used to it immediately with curiosity and interest.Costumer service is on superb level. Highly recommend 👍",
      },
      {
        name: "Private Account",
        rating: 5,
        message:
          "I love it so much even my cat. This product has made my life more easy👍",
      },
      {
        name: "Private Account",
        rating: 5,
        message:
          "The box arrived in a very good condition to me. Very simple to use it & my Cats use it immediately.Highly recommended",
      },
      {
        name: "Private Account",
        rating: 5,
        message:
          "As we foster cats, we have up to 13 cats at any given time so we got 2 boxes. The boxes stay clean, the cats like to use them, THE BOX DOES NOT SMELL!! With so many cats, these have held up PERFECTLY, they pause when a cat tries to enter while the box is cleaning, and make litter changes easy! These don't take up much room and look cool!",
      },
    ],
    productOrderSectionImg: LitterBoxOrderImg,
    seoMeta: {
      title: "",
      description: "",
    },
  },
  litterSand: {
    id: "bentonite-cat-litter",
    preNameText: "",
    productName: "Kipenzi Cat Litter",
    productDesc: "Cat-tested, cat-approved – because every meow matters.🐾",
    productRating: 4.8,
    cardImageFromMediaList: 0,
    mediaList: [
      {
        id: 1,
        type: MEDIA_TYPE.IMG,
        src: KipenziLitter1,
        thumbnail: KipenziLitter1,
        alt: "Kipenzi Automated Cat Litter Box",
      },
      {
        id: 2,
        src: KipenziLitter2,
        thumbnail: KipenziLitter2,
        alt: "Kipenzi Automated Cat Litter Box",
      },
      {
        id: 3,
        src: KipenziLitter3,
        thumbnail: KipenziLitter3,
        alt: "Kipenzi Automated Cat Litter Box",
      },
      {
        id: 4,
        src: KipenziLitter4,
        thumbnail: KipenziLitter4,
        alt: "Kipenzi Automated Cat Litter Box",
      },
      {
        id: 5,
        src: KipenziLitter5,
        thumbnail: KipenziLitter5,
        alt: "Kipenzi Automated Cat Litter Box",
      },
    ],
    aboutProduct: [
      {
        title: "Fights odor on contact",
        description:
          "Smell stays trapped deep inside the litter. Achieve over 97% ammonia reduction",
      },
      {
        title: "Purely All-Natural",
        description:
          "We're dedicated to your cat's health, which is why our cat litter is crafted using only the finest all-natural ingredients. No harmful chemicals or additives are found here.",
      },
      {
        title: "Litter that actually clumps",
        description:
          "Clumps tight for easy scooping. Effortlessly scoop and manage clumps, simplifying the task of maintaining a clean litter box.",
      },
      {
        title: "Fragrance",
        description: "Available in unscented and lavender fragrance.",
      },
    ],
    additionalStats: {
      oneDesktop: "20,000+ cats love it",
      oneMobile: "20k+ cats love it",
      twoDesktop: "100,000+ happy pet parents",
      twoMobile: "100k+ happy pet parents",
    },
    productFeatureImage: LitterSandFeatureImg,
    productFeatures: [
      {
        icon: LitterNatural,
        heading: "100% Natural",
        description: "This product is made of 100% natural bentonite clay",
      },
      {
        icon: LitterAbsorption,
        heading: "Ultra-Rapid Clumping",
        description:
          "This product quickly absorbs moisture upon contact with cat urine",
      },
      {
        icon: LitterNoDust,
        heading: "99% Dust Free",
        description:
          "It has a dust free formutation making it safe for pets & pet parents",
      },
      {
        icon: LitterOdorFree,
        heading: "Excellent Odour Control",
        description:
          "This litter features optimum odur control, helping you keep your home odor free",
      },
    ],
    testimonials: [
      {
        name: "Private Account",
        rating: 5,
        message:
          "The product is perfect and my cat already loved it after only one day! Definitely recommended.",
      },
      {
        name: "Private Account",
        rating: 5,
        message:
          "My cat used it already twice. He is a bit nervous when it's in the cleaning phase, but I think he will get used to it. For now, I keep the old and new toilets next to each other. Overall - very satisfied. Hope my cat will be satisfied as well with new fresh and clean litter for a long time!",
      },
      {
        name: "Private Account",
        rating: 5,
        message:
          "Very good quality product, my kittens got used to it immediately with curiosity and interest.Costumer service is on superb level. Highly recommend 👍",
      },
      {
        name: "Private Account",
        rating: 5,
        message:
          "I love it so much even my cat. This product has made my life more easy👍",
      },
      {
        name: "Private Account",
        rating: 5,
        message:
          "The box arrived in a very good condition to me. Very simple to use it & my Cats use it immediately.Highly recommended",
      },
      {
        name: "Private Account",
        rating: 5,
        message:
          "As we foster cats, we have up to 13 cats at any given time so we got 2 boxes. The boxes stay clean, the cats like to use them, THE BOX DOES NOT SMELL!! With so many cats, these have held up PERFECTLY, they pause when a cat tries to enter while the box is cleaning, and make litter changes easy! These don't take up much room and look cool!",
      },
    ],
    productOrderSectionImg: LitterSandOrderImg,
  },
  shampooTickFlea: {
    id: "shampoo-tick-and-flea",
    preNameText: "",
    productName: "Tick & Flea Shampoo",
    productDesc:
      "Bye-Bye Bugs, Hello Hugs! - The Pawsome Shampoo That Sends Pests Packing!",
    productRating: 4.8,
    cardImageFromMediaList: 0,
    mediaList: [
      {
        id: 1,
        type: MEDIA_TYPE.IMG,
        src: ShampooTickFleaSlideMain,
        thumbnail: ShampooTickFleaSlideMain,
        alt: "Kipenzi Shampoo Tick and Flea",
      },
      {
        id: 2,
        type: MEDIA_TYPE.IMG,
        src: ShampooTickFleaSlide2,
        thumbnail: ShampooTickFleaSlide2,
        alt: "Kipenzi Shampoo Tick and Flea",
      },
      {
        id: 3,
        type: MEDIA_TYPE.IMG,
        src: ShampooTickFleaSlide3,
        thumbnail: ShampooTickFleaSlide3,
        alt: "Kipenzi Shampoo Tick and Flea",
      },
      {
        id: 4,
        type: MEDIA_TYPE.IMG,
        src: ShampooTickFleaSlide4,
        thumbnail: ShampooTickFleaSlide4,
        alt: "Kipenzi Shampoo Tick and Flea",
      },
      {
        id: 5,
        type: MEDIA_TYPE.IMG,
        src: ShampooTickFleaSlide5,
        thumbnail: ShampooTickFleaSlide5,
        alt: "Kipenzi Shampoo Tick and Flea",
      },
    ],
    aboutProduct: [
      {
        title: "Bugs Be Gone",
        description:
          "Say goodbye to those pesky freeloaders! Our tick and flea shampoo kicks out the creepy crawlies faster than you can say “bath time!”",
      },
      {
        title: "Scratch No More",
        description:
          " Itchy pet? Not on our watch! This shampoo soothes irritated skin, so your fur baby can finally enjoy a scratch-free life.",
      },
      {
        title: "Fresh & Fabulous",
        description:
          "Not only does it evict pests, but it also leaves your pet smelling fresh as a daisy—so cuddle time can last all day!",
      },
      {
        title: "Safe Suds, Happy Pets",
        description:
          "Gentle on fur and tough on bugs, this formula is so safe even your grandma would approve. Shampoo time has never been this fun—or effective!",
      },
    ],
    additionalStats: {
      oneDesktop: "20,000+ cats love it",
      oneMobile: "20k+ cats love it",
      twoDesktop: "100,000+ happy pet parents",
      twoMobile: "100k+ happy pet parents",
    },
    productFeatureImage: ShampooTickFleaFeatureHighlight,
    productFeatures: [
      {
        icon: IconAntiFlea,
        heading: "Bug Buster",
        description: "Kills ticks, fleas, & eggs on contact",
      },
      {
        icon: IconHealing,
        heading: "Calm Care",
        description: "Calms & heals irritated skin from bites",
      },
      {
        icon: IconSecureShield,
        heading: "Pest Protector",
        description: "Provides protection from pests",
      },
      {
        icon: IconHealthy,
        heading: "Fur Flourish",
        description: "Nourishes fur for a shiny, pest-free coat",
      },
    ],
    testimonials: [],
    productOrderSectionImg: ShampooTickFleaFeatureHighlight,
  },
  shampoo8In1: {
    id: "shampoo-8-in-1",
    preNameText: "",
    productName: "8-in-1 Shampoo",
    productDesc:
      "Eight Wonders in One Bottle: Because Your Pet Deserves a Spa Day!",
    productRating: 4.8,
    cardImageFromMediaList: 0,
    mediaList: [
      {
        id: 1,
        type: MEDIA_TYPE.IMG,
        src: Shampoo8In1SlideMain,
        thumbnail: Shampoo8In1SlideMain,
        alt: "Kipenzi Shampoo 8 in 1",
      },
      {
        id: 2,
        type: MEDIA_TYPE.IMG,
        src: Shampoo8In1Slide2,
        thumbnail: Shampoo8In1Slide2,
        alt: "Kipenzi Shampoo 8 in 1",
      },
      {
        id: 3,
        type: MEDIA_TYPE.IMG,
        src: Shampoo8In1Slide3,
        thumbnail: Shampoo8In1Slide3,
        alt: "Kipenzi Shampoo 8 in 1",
      },
      {
        id: 4,
        type: MEDIA_TYPE.IMG,
        src: Shampoo8In1Slide4,
        thumbnail: Shampoo8In1Slide4,
        alt: "Kipenzi Shampoo 8 in 1",
      },
      {
        id: 5,
        type: MEDIA_TYPE.IMG,
        src: Shampoo8In1Slide5,
        thumbnail: Shampoo8In1Slide5,
        alt: "Kipenzi Shampoo 8 in 1",
      }
    ],
    aboutProduct: [
      {
        title: "Eight Powers",
        description:
          "One bottle, eight incredible functions—because multitasking is the way to go!",
      },
      {
        title: "All-in-One Magic",
        description:
          "Ditch the clutter! This shampoo covers all your pet’s needs in one easy step.",
      },
      {
        title: "Spa Day Bliss",
        description:
          "Treat your pet to a luxurious bath that cleans, conditions, and pampers.",
      },
      {
        title: "Glamorous Coat",
        description:
          "Get a shiny, happy fur that makes your pet the star of the show!",
      },
    ],
    additionalStats: {
      oneDesktop: "20,000+ cats love it",
      oneMobile: "20k+ cats love it",
      twoDesktop: "100,000+ happy pet parents",
      twoMobile: "100k+ happy pet parents",
    },
    productFeatureImage: Shampoo8In1FeatureHighlight,
    productFeatures: [
      {
        icon: IconReject,
        heading: "Coat Defender",
        description: "Fights bacteria and fungi for a healthier coat",
      },
      {
        icon: IconMite,
        heading: "Bug Bouncer",
        description: "Repels and kills ticks & fleas to protect your pet",
      },
      {
        icon: IconOdor,
        heading: "Odor Obliterator",
        description: "Controls and neutralizes unpleasant smells",
      },
      {
        icon: IconHair,
        heading: "Shedding Shield",
        description: "Minimizes shedding and keeps your home cleaner",
      },
    ],
    testimonials: [],
    productOrderSectionImg: Shampoo8In1FeatureHighlight,
  },
  shampooPuppyKitten: {
    id: "shampoo-puppy-and-kitten",
    preNameText: "",
    productName: "Puppy & Kitten Shampoo",
    productDesc:
      "Paws & Purrs Perfected: The Ultimate Shampoo for Your Furry Babies!",
    productRating: 4.8,
    cardImageFromMediaList: 0,
    mediaList: [
      {
        id: 1,
        type: MEDIA_TYPE.IMG,
        src: ShampooPupKittenSlideMain,
        thumbnail: ShampooPupKittenSlideMain,
        alt: "Kipenzi Shampoo Puppy And Kitten",
      },
      {
        id: 2,
        type: MEDIA_TYPE.IMG,
        src: ShampooPupKittenSlide2,
        thumbnail: ShampooPupKittenSlide2,
        alt: "Kipenzi Shampoo Puppy And Kitten",
      },
      {
        id: 3,
        type: MEDIA_TYPE.IMG,
        src: ShampooPupKittenSlide3,
        thumbnail: ShampooPupKittenSlide3,
        alt: "Kipenzi Shampoo Puppy And Kitten",
      },
      {
        id: 4,
        type: MEDIA_TYPE.IMG,
        src: ShampooPupKittenSlide4,
        thumbnail: ShampooPupKittenSlide4,
        alt: "Kipenzi Shampoo Puppy And Kitten",
      },
      {
        id: 5,
        type: MEDIA_TYPE.IMG,
        src: ShampooPupKittenSlide5,
        thumbnail: ShampooPupKittenSlide5,
        alt: "Kipenzi Shampoo Puppy And Kitten",
      },
    ],
    aboutProduct: [
      {
        title: "Tiny Tots, Big Clean",
        description:
          "Perfect for little furballs who need a thorough wash without the fuss.",
      },
      {
        title: "Gentle & Cuddly",
        description:
          "As soft as a kitten’s purr and as mild as a puppy’s nuzzle.",
      },
      {
        title: "Snuggle-Ready",
        description:
          "Leaves your pets fresh and cuddly for extra-snug sessions.",
      },
      {
        title: "Tear-Free Joy",
        description:
          "Bath time is fun again with our gentle, tear-free formula!",
      },
    ],
    additionalStats: {
      oneDesktop: "20,000+ cats love it",
      oneMobile: "20k+ cats love it",
      twoDesktop: "100,000+ happy pet parents",
      twoMobile: "100k+ happy pet parents",
    },
    productFeatureImage: ShampooPupKittenFeatureHighlight,
    productFeatures: [
      {
        icon: IconHealing,
        heading: "Hydrating Coconut",
        description: "Coconut deeply hydrates & prevents dryness ",
      },
      {
        icon: IconHealed,
        heading: "Soothing Rosemary",
        description: "Rosemary soothes skin & reduces inflammation",
      },
      {
        icon: IconSkinGeneration,
        heading: "Healing Aloe",
        description: "Aloe Vera aids in wound healing & skin regeneration Coco",
      },
      {
        icon: IconHair,
        heading: "Gentle Glucoside",
        description: "Glucoside minimizes irritation for sensitive skin",
      },
    ],
    testimonials: [],
    productOrderSectionImg: ShampooPupKittenFeatureHighlight,
  },
};
