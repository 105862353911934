import axios from "axios";
import React from "react";
import DarkButton from "../../../../components/atoms/DarkButton/DarkButton";
import InputBox from "../../../../components/atoms/InputBox/InputBox";
import Popup from "../../../../components/molecules/Popup/Popup";
import OrderLeftSection from "../OrderLeftSection/OrderLeftSection";

import classes from "./OrderNowSection.module.css";

const OrderNowSection = ({ data }) => {
  const intialState = { showPopup: false };
  const [state, setState] = React.useState({ ...intialState });

  const onFormSubmit = (e) => {
    e.preventDefault();
    const fullName = e.target.fullName.value;
    let firstName = "";
    let lastName = e.target.fullName.value;
    if (fullName.split(" ").length > 1) {
      firstName = fullName.split(" ")[0];
      lastName = fullName.split(" ").slice(1).join(" ");
    }
    let formData = new FormData();
    formData.append(
      "xnQsjsdp",
      "8f58ed1bfd8097d5028e479a123d3f351dba58345e4b90d9b9b9b8653eb4a592"
    );
    formData.append(
      "xmIwtLD",
      "5762e6a89885ea47f27bf352c49cef763fef674a37041f09f080cfb8ef21751b"
    );
    formData.append("zc_gad", "");
    formData.append("actionType", "TGVhZHM=");
    formData.append("First Name", firstName);
    formData.append("Last Name", lastName);
    formData.append("Email", e.target.email.value);
    formData.append("Mobile", "+91" + e.target.contactNumber.value);
    formData.append("Description", data.id);

    const utmSource = localStorage.getItem("utm_source");
    const utmMedium = localStorage.getItem("utm_medium");
    if (utmSource && utmMedium) {
      formData.append(
        "Skype ID",
        `utm_source=${utmSource}&utm_medium=${utmMedium}`
      );
    }

    axios
      .post("https://crm.zoho.in/crm/WebToLeadForm", formData)
      .then((response) => {
        setState({ ...state, showPopup: true });
        e.target.reset();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const hidePopup = () => {
    setState({ ...state, showPopup: false });
  };

  return (
    <section className={classes.MainContainer}>
      <h2 className={classes.MainHeading}>Order Now!</h2>
      <div className={classes.SectionWrapper}>
        <div className={classes.StepWrapper}>
          <OrderLeftSection data={data} />
        </div>
        <div className={classes.FormWrapper}>
          <h2 className={classes.FormHeading}>Place your order</h2>
          <p className={classes.PaymentInfo}>
            Show your interest by filling the form to get the special launch
            price
          </p>
          <form
            onSubmit={onFormSubmit}
            name="WebToLeads589356000000310009"
            method="POST"
          >
            <InputBox placeholder={"Full Name"} name={"fullName"} />
            <InputBox placeholder={"Email Address"} name={"email"} />
            <InputBox placeholder={"Contact Number"} name={"contactNumber"} />

            {/* <p className={classes.Price}>
              Price: ₹25,000
              <span className={classes.MPRWrapper}>
                MRP <span className={classes.MRP}>₹35,000</span>
                <span className={classes.Discount}>(28% OFF)</span>
              </span>
            </p> */}
            <div className={classes.ButtonWrapper}>
              <DarkButton
                fullWidth
                label="Order Now"
                icon={<i className="fas fa-arrow-right"></i>}
              />
            </div>

            <div className={classes.AdditionalStats}>
              <p className={classes.DesktopOnly}>20,000+ cats love it</p>
              <p className={classes.MobileOnly}>20k+ cats love it</p>
              <div className={classes.VerticalDivider}></div>
              <p className={classes.DesktopOnly}>100,000+ happy pet parents</p>
              <p className={classes.MobileOnly}>100k+ happy pet parents</p>
            </div>
          </form>
        </div>
      </div>
      <Popup showPopup={state.showPopup} hidePopup={hidePopup}>
        <div>
          <h2 className={classes.PopupText}>Order Successful!</h2>
          <p className={classes.PopupDesc}>
            We will get in touch with you on WhatsApp for payment details.
          </p>
        </div>
      </Popup>
    </section>
  );
};

export default OrderNowSection;
