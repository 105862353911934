import React from "react";
import ImageGallery from "../../../../components/molecules/ImageGallery/ImageGallery";
import DarkButton from "../../../../components/atoms/DarkButton/DarkButton";

import classes from "./ProductPreviewSection.module.css";

import RatingStar5 from "../../../../assets/icons/5-star.png";
import { getRatingStars } from "../../../../utils/HelperFunctions";

const ProductPreviewSection = ({
  scrollToOrderSection,
  scrollToReviewSection,
  data
}) => {
  return (
    <section className={classes.MainWrapper}>
      <div className={classes.MediaSection}>
        <div className={classes.MobileOnly}>
          <p className={classes.PreHeading}>{data.preNameText}</p>
          <h1 className={classes.MainHeading}>{data.productName}</h1>
          <div
            className={classes.RatingWrapper}
            onClick={scrollToReviewSection}
          >
            <p className={classes.Rating}>{data.productRating}</p>
            <img src={getRatingStars(data.productRating)} alt={`Kipenzi ${data.productName} Rating`} />
          </div>
        </div>
        <ImageGallery mediaList={data.mediaList} />
      </div>
      <div className={classes.TextSection}>
        <div className={classes.DesktopOnly}>
          <p className={classes.PreHeading}>{data.preNameText}</p>
          <h1 className={classes.MainHeading}>{data.productName}</h1>
          <div
            className={classes.RatingWrapper}
            onClick={scrollToReviewSection}
          >
            <p className={classes.Rating}>{data.productRating}</p>
            <img src={RatingStar5} alt={`Kipenzi ${data.productName} Rating`} />
          </div>
        </div>
        <p> {data.productDesc}</p>

        <p>About the product:</p>
        <ul>
          {data.aboutProduct.map((item, pos) => {
            return (
              <li className={classes.AboutItem}>
                <span>{item.title}</span>: {item.description}
              </li>
            );
          })}
        </ul>

        <div className={classes.OrderButtonWrapper}>
          <DarkButton
            fullWidth
            label="Order Now"
            icon={<i className="fas fa-arrow-right"></i>}
            click={scrollToOrderSection}
          />
        </div>

        <div className={classes.AdditionalStats}>
          <p className={classes.DesktopOnly}>{data.additionalStats.oneDesktop}</p>
          <p className={classes.MobileOnly}>{data.additionalStats.oneMobile}</p>
          <div className={classes.VerticalDivider}></div>
          <p className={classes.DesktopOnly}>{data.additionalStats.twoDesktop}</p>
          <p className={classes.MobileOnly}>{data.additionalStats.twoMobile}</p>
        </div>
      </div>
    </section>
  );
};

export default ProductPreviewSection;
