import React from "react";
import classes from "./FeatureItem.module.css";

const FeatureItem = ({ icon, heading, description }) => {
  return (
    <div className={classes.FeatureItem}>
      <img className={classes.Icon} src={icon} alt={heading} />
      <h3 className={classes.Heading}>{heading}</h3>
      <p className={classes.Description}>{description}</p>
    </div>
  );
};

export default FeatureItem;
