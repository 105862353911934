import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import ProductDetailPage from "./containers/ProductDetailPage/ProductDetailPage";

import classes from "./App.module.css";
import Footer from "./components/molecules/Footer/Footer";
import { ROUTE_PATHS } from "./utils/RoutePath";
import AboutPage from "./containers/AboutPage/AboutPage";
import PrivacyPolicyPage from "./containers/PrivacyPolicyPage/PrivacyPolicyPage";
import RefundPolicyPage from "./containers/RefundPolicyPage/RefundPolicyPage";
import Topbar from "./components/molecules/Topbar/Topbar";
import TermsPage from "./containers/TermsPage/TermsPage";
import Homepage from "./containers/Homepage/Homepage";

function App() {
  const handleUTM = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const utmSource = urlParams.get("utm_source");
    const utmMedium = urlParams.get("utm_medium");

    if (utmSource && utmMedium) {
      localStorage.setItem("utm_source", utmSource);
      localStorage.setItem("utm_medium", utmMedium);
    }
  };

  return (
    <BrowserRouter>
      <div className={classes.MainContainer}>
        {handleUTM()}
        <Topbar />
        <Routes>
          <Route path={ROUTE_PATHS.HOME_PAGE} element={<Homepage />} />
          <Route
            path={ROUTE_PATHS.PRODUCT_DETAILS}
            element={<ProductDetailPage />}
          >
            <Route path=":id" element={<ProductDetailPage />} />
          </Route>
          <Route path={ROUTE_PATHS.ABOUT} element={<AboutPage />} />
          <Route path={ROUTE_PATHS.TNC} element={<TermsPage />} />
          <Route path={ROUTE_PATHS.PRIVACY} element={<PrivacyPolicyPage />} />
          <Route
            path={ROUTE_PATHS.REFUND_POLICY}
            element={<RefundPolicyPage />}
          />
          <Route
            path="*"
            element={<Navigate to={ROUTE_PATHS.DEFAULT_PRODUCT} />}
          />
        </Routes>
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
