import React from "react";

import classes from "./Footer.module.css";
import { Link } from "react-router-dom";
import { ROUTE_PATHS } from "../../../utils/RoutePath";

const Footer = () => {
  return (
    <footer className={classes.Footer}>
      <div className={classes.FooterTopSection}>
        <div>
          <div className={classes.FooterLinkWrapper}>
            <Link className={classes.FooterLink} to={ROUTE_PATHS.ABOUT}>
              About
            </Link>
            <Link className={classes.FooterLink} to={ROUTE_PATHS.TNC}>
              Terms & Conditions
            </Link>
            <Link className={classes.FooterLink} to={ROUTE_PATHS.PRIVACY}>
              Privacy Policy
            </Link>
            <Link className={classes.FooterLink} to={ROUTE_PATHS.REFUND_POLICY}>
              Shipping & Refund Policy
            </Link>
            {/* <span>Made with</span>
          <i className="fas fa-heart"></i>
          <span>in India</span> */}
          </div>
        </div>

        <div className={classes.ContactInfoWrapper}>
          <div className={classes.ContactInfo}>
            <i class="fas fa-envelope"></i> info@kipenzistore.com
          </div>
          <div className={[classes.ContactInfo, classes.Phone].join(" ")}>
            <i className="fas fa-phone"></i> +91 9899113898
          </div>
        </div>
      </div>
      <div className={classes.CorporateAddress}>
        Corporate Office: Vikram Kalia HUF, 1st Floor, Plot no 61 Chandra Park, Opposite NSIT
        Dwarka, New Delhi-110078
      </div>
    </footer>
  );
};

export default Footer;
