import React, { useEffect } from "react";
import classes from "./RefundPolicyPage.module.css";

const RefundPolicyPage = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className={classes.MainContainer}>
      <h1 className={classes.MainHeading}> Shipping Policy </h1>
      <h3> How does the delivery process work? </h3>
      <ol>
        <li>
          {" "}
          Once our system processes your order, your products are inspected
          thoroughly to ensure they are in perfect condition.{" "}
        </li>
        <li>
          {" "}
          After they pass through the final round of quality checks, they are
          packed and handed over to our trusted delivery partner.{" "}
        </li>
        <li>
          {" "}
          Our delivery partners then bring the package to you at the earliest
          possibility. In case, they are unable to reach your provided address
          or at a suitable time, they will contact you to resolve the issue.{" "}
        </li>
      </ol>
      <h3> What is the estimated delivery time? </h3>
      <p>
        {" "}
        We usually dispatch most orders within 1-4 business days (excluding
        Sundays and public holidays) for Local & National Regions.{" "}
      </p>
      <h3> Does Kipenzi ship outside India? </h3>
      <p> Kipenzi does not ship internationally currently. </p>

      <h1 className={classes.MainHeading}>Refund Policy</h1>
      <p>
        Definition: <b>‘Return’</b> is defined as the action of giving back the
        item purchased by the customer to the Seller/Kipenzi on the Kipenzi
        website. Following situations may arise:
      </p>
      <ol>
        <li>Item was defective</li>
        <li>Item was damaged in transit</li>
        <li>Products was / were missing</li>
        <li>Wrong item was received</li>
      </ol>
      <p>Return could also result in a refund of money in some of the cases.</p>
      <p>
        We encourage the customer to review the listing before making the
        purchase decision. In case the customer orders a wrong item, Kipenzi
        /seller shall not be entitled to any return/refund.
      </p>
      <p> The following RULES apply to this policy:</p>
      <ol>
        <li>
          Certain consumable items will not be returned or refunded, the list of
          such items include in case the seal is found to be broken. The list of
          such items includes food, grooming products, supplements, dog apparel,
          beds, car seat covers etc
        </li>
        <li>
          In case of damaged or any missing product the customer needs to raise
          the request within 24 hours from the time of delivery. Once the
          customer has raised the request, he/she needs to provide a visual
          proof (an image) of the damaged product to Kipenzi within 3 days from
          the time of delivery.
        </li>
        <li>
          All items to be returned must be unused and in their original
          condition with all original tags and packaging intact and should not
          be broken or tampered with.
        </li>
        <li>
          In the event the Seller accepts the return request raised by the
          customer, customer will have to return the product and then the refund
          shall be credited to the customer’s account.
        </li>
        <li>
          Refund will only be processed after a quality check of the product by
          experts of Kipenzi
        </li>
      </ol>
      <p>
        Please note that the product should be unused and should be sent back
        along with original box and invoice to:
      </p>
      <b>
        <p>Customer care</p>
        <p>Vikram Kalia HUF</p>
        <p>
          1st Floor, Plot no 61 Chandra Park, Opposite NSIT Dwarka, New
          Delhi-110078
        </p>
      </b>
      <p>
        The refund process will be initiated once we have received the
        product(s). Typically refunds are processed in less than 15 working days
        but in case of payments by Cheque or DD, it may take a few extra days
        for the cheque to be delivered to your billing address, and for the
        funds to be credited to your account, once you deposit the cheque.
      </p>
    </main>
  );
};

export default RefundPolicyPage;
