import React from "react";
import { Carousel } from "react-responsive-carousel";

import classes from "./ImageGallery.module.css";

const ImageGallery = ({ mediaList }) => {
  const [currentPos, setCurrentPos] = React.useState(0);

  return (
    <div className={classes.BannerCarousel}>
      <div className={classes.ThumbnailWrapper}>
        {mediaList.map((item, pos) => (
          <img
            className={[
              classes.Thumbnail,
              currentPos === pos ? classes.SelectedThumbnail : null,
            ].join(" ")}
            key={pos}
            src={item.thumbnail}
            alt={item.alt}
            onClick={() => setCurrentPos(pos)}
          />
        ))}
      </div>
      <Carousel
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        autoPlay={true}
        infiniteLoop={true}
        swipeable={true}
        stopOnHover={true}
        emulateTouch={true}
        selectedItem={currentPos}
        onChange={(pos, item) => {
          setCurrentPos(pos);
        }}
      >
        {mediaList.map((item) => (
          <div className={classes.PreviewImageWrapper}>
            <img
              className={classes.PreviewImage}
              src={item.src}
              alt={item.alt}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default ImageGallery;
