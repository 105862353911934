import React from "react";

import classes from "./OrderLeftSection.module.css";

const OrderLeftSection = ({data}) => {
  return (
    <div className={classes.MainContainer}>
      <img
        className={classes.Illustration}
        src={data.productOrderSectionImg}
        alt="Kipenzi Smart Cat Litter Box"
      />
    </div>
  );
};

export default OrderLeftSection;
